var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"order"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"flex-nowrap-pc"},[(_vm.$route.name !== 'OrderPrintLabel' && _vm.$route.name !== 'PrintOrderSummary' && _vm.$route.name !== 'PrintOrderSummaryList')?_c('v-col',{staticClass:"order-status-list-desktop",attrs:{"cols":"auto"}},_vm._l((_vm.statusList),function(status,key){return _c('router-link',{key:`status-link-${key}`,class:`status-box elevation-1 status-box-${key}`,attrs:{"tag":"button","to":{
            name: 'OrderListByStatus',
            params: { orderStatus: key },
            query: _vm.routerQuery
          },"disabled":_vm.isFetchingOrderList}},[_c('div',{staticClass:"status-label"},[_vm._v(" "+_vm._s(status.label)+" ")]),(!_vm.isFetchingOrderCount)?_c('div',{staticClass:"status-value"},[_vm._v(" "+_vm._s(status.count)+" ")]):_c('LoadingMeatBall',{staticClass:"status-value"})],1)}),1):_vm._e(),_c('v-col',{staticClass:"order-status-list-mobile",staticStyle:{"overflow":"auto"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex text-center",staticStyle:{"width":"fit-content"}},_vm._l((_vm.statusList),function(status,key){return _c('router-link',{key:`status-link-${key}`,class:`status-mb-box status-mb-box-${key}`,attrs:{"tag":"button","to":{
              name: 'OrderListByStatus',
              params: { orderStatus: key },
              query: _vm.routerQuery
            },"disabled":_vm.isFetchingOrderList}},[_c('div',[_vm._v(_vm._s(status.label)+" ")]),(!_vm.isFetchingOrderCount)?_c('div',{staticClass:"status-value"},[_vm._v(" "+_vm._s(status.count)+" ")]):_c('LoadingMeatBall',{staticStyle:{"left":"0px"}})],1)}),1)]),_c('v-col',{staticStyle:{"overflow":"auto"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }